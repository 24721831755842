<template>
  <div>
    <div class="bg-white px-10 py-4">
      <p class="text-xl font-bold">Kategorije proizvoda</p>
    </div>
	<hr>
    <div class="w-full flex flex-col items-center">
      <TabGroup>
        <div class="py-2">
          <TabList class="flex p-1 space-x-1 rounded-xl bg-gray-200">
            <Tab v-slot="{ selected }" v-for="tab in tabs">
              <button
                class="
                  w-28
                  py-2
                  text-sm
                  font-inter
                  rounded-lg
                  focus:outline-none
                  focus:ring-2
                  ring-offset-2 ring-white ring-opacity-60
                "
                :class="[
                  selected ? 'bg-white text-gray-900' : 'bg-gray-200 text-gray-900',
                ]"
              >
                {{ tab.name }}
              </button>
            </Tab>
          </TabList>
        </div>
        <div class="w-full">
          <TabPanels>
            <TabPanel><List /></TabPanel>
          </TabPanels>
          <TabPanels>
            <TabPanel><Add /></TabPanel>
          </TabPanels>
          <!-- <TabPanels>
            <TabPanel><Edit /></TabPanel>
          </TabPanels> -->
        </div>
      </TabGroup>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import Add from "@/views/shop/categories/Add.vue";
import List from "@/views/shop/categories/List.vue";


export default {
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Add,
	List,
  },
  setup() {
    let tabs = ref([
      { name: "Kategorije" },
      { name: "Dodaj" },
    //   { name: "Uredi" },
    ]);
    return { tabs };
  },
};
</script>