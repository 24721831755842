<template>
  <div class="flex flex-col lg:w-3/5 h-full m-10">
    <div class="grid lg:grid-cols-2 lg:gap-x-20 gap-5">
      <div class="flex flex-col">
        <p>Ime kategorije</p>
        <input class="input" type="text" v-model="name" />
      </div>
      <div class="flex flex-col">
        <p>Vrsta kategorije</p>
        <Listbox v-model="odabranaVrsta">
          <div class="relative">
            <ListboxButton
              class="
                relative
                w-full
                h-10
                pl-5
                text-left
                bg-gray-200
                rounded-lg
                cursor-default
                focus:outline-none
                focus-visible:ring-2
                focus-visible:ring-opacity-75
                focus-visible:ring-white
                focus-visible:ring-offset-orange-300
                focus-visible:ring-offset-2
                focus-visible:border-indigo-500
                sm:text-sm
              "
            >
              <span class="block truncate">{{ odabranaVrsta.name }}</span>
              <span
                class="
                  absolute
                  inset-y-0
                  right-0
                  flex
                  items-center
                  pr-2
                  pointer-events-none
                "
              >
                <SelectorIcon
                  class="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="
                  z-10
                  absolute
                  w-full
                  py-1
                  mt-1
                  overflow-auto
                  text-base
                  bg-white
                  rounded-md
                  shadow-lg
                  max-h-60
                  ring-1 ring-black ring-opacity-5
                  focus:outline-none
                  sm:text-sm
                "
              >
                <ListboxOption
                  v-slot="{ active, selected }"
                  v-for="person in vrsta"
                  :key="person.name"
                  :value="person"
                  as="template"
                >
                  <li
                    :class="[
                      active ? 'bg-gray-100' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-10 pr-4',
                    ]"
                  >
                    <span
                      :class="[
                        selected ? 'font-bold' : 'font-normal',
                        'block truncate',
                      ]"
                      >{{ person.name }}</span
                    >
                    <span
                      v-if="selected"
                      class="
                        absolute
                        inset-y-0
                        left-0
                        flex
                        items-center
                        pl-3
                        text-gray-600
                      "
                    >
                      <CheckIcon class="w-5 h-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>
      <div class="flex flex-col">
        <p>Atributi</p>
        <div class="flex flex-row gap-3 pr-1">
          <input
            class="input w-full"
            type="text"
            v-model="atributInput"
            @keyup.enter="dodajAtribut"
          />
          <button @click="dodajAtribut">
            <i class="fas fa-plus text-gray-500"></i>
          </button>
        </div>
        <div class="flex flex-row gap-2 py-3 flex-wrap">
          <div
            class="flex gap-2 p-2 bg-gray-200 rounded-lg items-center"
            v-for="atribut in atributi"
          >
            <p>{{ atribut }}</p>
            <button @click="makniAtribut(atribut)">
              <i class="fas fa-times transform scale-90 text-gray-600"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="flex flex-row-reverse gap-2 items-center float-right m-2">
      <button
        class="p-3 hover:bg-gray-200 bg-gray-300 rounded-lg"
        @click="dodaj"
      >
        Dodaj
      </button>
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";

import { addCategory } from "@/helpers/api.js";

export default {
  components: {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon,
  },

  setup() {
    const vrsta = [
      { name: "Samostojeći", type: 0 },
      { name: "Ugradbeni", type: 1 },
      { name: "Samostojeći i ugradbeni", type: 2 },
    ];
    const odabranaVrsta = ref(vrsta[0]);

    let name = ref();
    let atributi = ref([]);
    let atributInput = ref();
    let message = ref();

    const dodajAtribut = () => {
      if (atributInput.value.length == 0) return;
      atributi.value.push(atributInput.value);
      atributInput.value = "";
    };

    const makniAtribut = (atribut) => {
      atributi.value = atributi.value.filter((a) => a != atribut);
    };

    const dodaj = async () => {
      const data = {
        name: name.value,
        type: odabranaVrsta.value,
        attributes: atributi.value,
      };
      try {
        let resp = await addCategory(data);
        console.log(resp);
        message.value = resp.data.message;
        setTimeout(() => {
          message.value = "";
        }, 5000);
      } catch (error) {
        message.value = error.response.data.message;
        setTimeout(() => {
          message.value = "";
        }, 5000);
      }
    };

    return {
      name,
      atributi,
      atributInput,
      dodajAtribut,
      makniAtribut,
      vrsta,
      odabranaVrsta,
      dodaj,
      message,
    };
  },
};
</script>

<style>
</style>